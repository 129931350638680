import React from 'react';
import caiProdOverviewImg from '../../images/cai_software_overview.png';
import cherryLakeMapAppImg from '../../images/cherrylake-map-app.png';
import scoutingIpadImg from '../../images/scouting_web_ipad.png';
import googlePlayImg from '../../images/google-play-badge.png';
import appStoreImg from '../../images/appstore-badge.svg';
import calloutImg from '../../images/cai_software_datamanagementtools.png';
import animalAppMock from '../../images/animal-app-screen-mock.png';
import animalAppLogo from '../../images/animal-app-logo.png';
import SectionLayout, { SectionLayout2 } from '../../layouts/SectionLayout';
import SEO from '../../components/SEO';
import useCdnUrl from '../../components/useCdnUrl';

export default function Software() {
  const cdnBaseUrl = useCdnUrl();
  return (
    <>
      <SEO title="" />
      <SectionLayout2>
        <p className="d-flex flex-column flex-md-row justify-content-center section-display-subheader">
          <span>COMPLEX DATA.</span>
          <span>&nbsp; SIMPLIFIED SOLUTIONS.</span>
        </p>
        <div className="row mb-3 section-copy">
          <div className="col-sm-6">
            <p className="float-md-right" style={{ maxWidth: '400px' }}>
              {`Farming is complex enough. Managing your data shouldn't be.
                We've developed the industry's first user-friendly software
                platform that brings together the most challenging data in
                Agriculture—Inventory, Imagery and Insights.`}
            </p>
          </div>

          <div className="col-sm-6">
            <p style={{ maxWidth: '400px' }}>
              No matter the size of your Ag operation, our software combines all
              of your data with all of your inventory and places it in the palm
              of your hand, giving you a level of insight that was once
              unimaginable.
            </p>
          </div>
        </div>
      </SectionLayout2>
      <div className="container">
        <div
          style={{ maxWidth: '950px', margin: '0 auto' }}
          className="p-3 mb-3 text-center"
        >
          <img
            className="img-fluid"
            alt="CAI product overview"
            src={caiProdOverviewImg}
          />
          <div className="mt-3 mt-sm-0">
            <a href="https://apps.apple.com/us/app/cultivateai-scouting/id1547278113?itsct=apps_box_badge&amp;itscg=30200">
              <img
                src={appStoreImg}
                // src="https://tools.applemediaservices.com/api/badges/download-on-the-app-store/black/en-us?size=250x83&amp;releaseDate=1611446400&h=4e2f63ef5556dd8573fa55d4dafd0af1"
                alt="Download on the App Store"
                style={{ height: '40px' }}
              />
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.cultivateai.scouting&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
              <img
                alt="Get it on Google Play"
                src={googlePlayImg}
                // src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                style={{ height: '60px' }}
              />
            </a>
          </div>
          {/* </div> */}
        </div>
      </div>
      <SectionLayout
        className="m-0 pt-3"
        bgColor="#00D0Eb"
        showBottomBorder={false}
        marginBottom={false}
      >
        <div className="mobile-desktop-text-margin-10">
          <div className="row">
            <div className="col-md-7 order-2 order-md-1 text-dark mt-4 mt-md-5 text-center text-md-left">
              <div className="mr-sm-5 mr-md-0 ml-sm-5">
                <h4 style={{ maxWidth: '430px' }}>
                  <strong>SCOUTING</strong> CUSTOMIZED <br /> FOR YOUR OPERATION
                </h4>
                <p>
                  {`Whether you're a private farm operator, Ag Service Provider,
                  Agronomist, or University Researcher - The CultivateAI Mobile
                  Scouting App allows you and your team to document custom,
                  location-specific, in-field information and record Harvest or
                  Spray records on a mobile phone, in real-time.`}
                </p>
                <p className="font-weight-bold">
                  <a
                    style={{ textDecoration: 'underline' }}
                    className="text-dark"
                    href={`${cdnBaseUrl}marketing/products/CAI_Scouting_App_OnePager.pdf`}
                  >
                    Download more info here.
                  </a>
                </p>
              </div>
            </div>
            <div className="col-md-5 order-1 order-md-2 d-flex align-items-end justify-content-center justify-content-md-end">
              <img
                className="scouting-mobile-img px-3"
                alt="CAI mobile scouting in field"
                src={scoutingIpadImg}
              />
            </div>
          </div>
        </div>
      </SectionLayout>
      <SectionLayout
        className="m-0 pb-3 pb-md-0"
        bgColor="#f2f2f2"
        marginBottom={false}
        showBottomBorder={false}
      >
        <div className="mobile-desktop-text-margin-10">
          <div className="row mt-3">
            <div className="col-md-5 d-flex align-items-end justify-content-center justify-content-md-end">
              <img
                className="animal-mobile-img"
                alt="CAI Animal app screenshot"
                src={animalAppMock}
              />
            </div>
            <div className="col-md-7 d-flex mt-3 text-dark text-center text-md-left">
              <div className="ml-sm-5 mr-sm-5 pr-md-5">
                <img
                  alt="CAI Animal Assets logo"
                  className="mb-3"
                  src={animalAppLogo}
                  style={{ width: '80px' }}
                />
                <h4
                  style={{ whiteSpace: 'nowrap' }}
                  className="d-flex flex-column flex-md-row"
                >
                  <strong>ANIMAL RECORDS</strong>
                  <span>&nbsp; IN REAL-TIME</span>
                </h4>
                <p>
                  {`Quickly capture and organize animal records for any species
                  and/or breed in real-time. Whether it be vaccinations, gestation, weight or
                  pasture/paddock grazing data - it's all synced with your Ag
                  Organization's cloud storage and trackable immediately.`}
                </p>
                <span className="d-flex align-items-center justify-content-center justify-content-md-start mt-5">
                  <a href="https://apps.apple.com/sa/app/cultivateai-animal-records/id1627313228">
                    <img
                      src={appStoreImg}
                      alt="Download on the App Store"
                      style={{ height: '40px' }}
                    />
                  </a>
                  <a href="https://play.google.com/store/apps/details?id=com.cultivateai.animals&pli=1">
                    <img
                      alt="Get it on Google Play"
                      src={googlePlayImg}
                      style={{ height: '60px' }}
                    />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </SectionLayout>
      <SectionLayout
        className="m-0 mb-5 px-3 p-md-0"
        bgColor="#D5E100"
        showBottomBorder={false}
      >
        <div className="mobile-desktop-text-margin-10">
          <div className="row flex-column-reverse flex-md-row">
            <div className="col-md-7 text-dark mt-3 text-center text-md-left">
              <div className="mr-sm-5 ml-sm-5 mt-sm-5">
                <h4 style={{ maxWidth: '430px' }}>
                  HELP GUESTS NAVIGATE YOUR LAND WITH OUR{' '}
                  <strong>PROPERTY MAP APP</strong>
                </h4>
                <p>
                  {`Looking for a way to allow your visitors or customers to
                  safely and efficiently navigate your property with or without
                  cell service? CultivateAI's Property Map Application provides
                  a unique, branded mobile application for your visitors to view
                  custom maps of your property - all with their mobile phone
                  location.`}
                </p>
                <p className="font-weight-bold">
                  <a
                    className="text-dark"
                    style={{ textDecoration: 'underline' }}
                    href={`${cdnBaseUrl}marketing/products/CAI_PropertyMapApp_OnePager.pdf`}
                  >
                    Download more info here.
                  </a>
                </p>
                <p>
                  Interested in more detail? The digital maps included in this
                  product also serve as a baseline to build upon any of the
                  following tools in your data silo:
                </p>
              </div>
            </div>
            <div className="col-md-5 d-flex align-items-end justify-content-center justify-content-sm-center justify-content-md-end">
              <img
                className="cherrylake-mobile-img"
                alt="CAI Mobile Map App screenshot"
                src={cherryLakeMapAppImg}
              />
            </div>
          </div>
        </div>
      </SectionLayout>
      <SectionLayout leftClasses="justify-content-center mt-0">
        <h4 className="section-display-subheader" style={{ fontWeight: 700 }}>
          Tools for data management include:
        </h4>
        <div style={{ margin: '0 auto', maxWidth: '650px' }}>
          <img
            className="img-fluid"
            alt="Insights, inventory and imagery overlap diagram"
            src={calloutImg}
          />
        </div>
      </SectionLayout>
    </>
  );
}
